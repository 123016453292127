.logo-component {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 254px;
  height: 150px;

  &--s-50 {
    width: 85px;
    height: 50px;
  }
}

